import React from "react";
import './explanation_page.css'
import {FormattedMessage} from "react-intl";
import step1 from "../assets/learn-more/Illustration/STEP/STEP-01.png";
import step2 from "../assets/learn-more/Illustration/STEP/STEP-02.png";
import step3 from "../assets/learn-more/Illustration/STEP/STEP-03.png";
import voc1 from "../assets/learn-more/Illustration/VOCAB/VOCAB-01.png";
import voc2 from "../assets/learn-more/Illustration/VOCAB/VOCAB-02.png";
import voc3 from "../assets/learn-more/Illustration/VOCAB/VOCAB-03.png";
import voc4 from "../assets/learn-more/Illustration/VOCAB/VOCAB-04.png";
import blockchain1 from "../assets/learn-more/Illustration/BLOCKCHAIN/blockain only-01.png";
import blockchain2 from "../assets/learn-more/Illustration/BLOCKCHAIN/blockain only-03.png";
import correct1 from "../assets/learn-more/Illustration/CORRECT/correct-01.png";
import correct2 from "../assets/learn-more/Illustration/CORRECT/correct-02.png";
import wrong1 from "../assets/learn-more/Illustration/WRONG/wrong-01.png";
import wrong2 from "../assets/learn-more/Illustration/WRONG/wrong-02.png";




function explanationPage() {

    return (
        <div className="LearnMore">
            <section className="container">
                <div className="Header">
                    <h1><FormattedMessage
                        id="learnmore.header"
                        defaultMessage='Proof of Immuability'
                        description="learn more header"/></h1>

                    <h6><FormattedMessage
                        id="learnmore.header.desc"
                        defaultMessage='is one of the underlying aspect of Blockchain that embraces other use cases then financing'
                        description="learn more header"/></h6>

                    <br/>
                    <h4><b><FormattedMessage
                        id="learnmore.hiw.title"
                        defaultMessage='HOW IT WORK'
                        description="learn more header"/></b></h4>

                    <p className="explanation_par"><FormattedMessage
                        id="learnmore.hiw.desc"
                        defaultMessage='We attach a sort of digital footprint to each contract. It is the footprint of the document that is inserted into the distributed registry, not the document itself. This footprint is unique for each document, the slightest modification of the data of a contract completely changes its digital footprint.'
                        description="learn more header"/></p>
                </div>

                <br/>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <img src={step1} className="illustration_midsize" alt="contract" />
                            <p className="img_desc"><FormattedMessage
                                id="learnmore.step1.desc"
                                defaultMessage='Document of George Mentes_AXA Car insurance'/></p>
                        </div>
                        <div className="col">
                            <img src={step2} className="illustration_midsize" alt="contract" />
                            <p className="img_desc"><FormattedMessage
                                id="learnmore.step2.desc"
                                defaultMessage='Creation of digital footprint'/></p>
                        </div>
                    </div>

                </div>
                <br/>
                <p className="explanation_par"><FormattedMessage
                    id="learnmore.step3.before"
                    defaultMessage='The digital footprint is then inserted in the Blockchain'/></p>

                <img src={step3} className="illustration_fullsize" alt="contract" />
                <p className="img_desc"><FormattedMessage
                    id="learnmore.step3.desc"
                    defaultMessage='*Blockchain : Distributed and decentralized. Synchronised peer to peer system'/></p>

                <div className="container">
                    <div className="row">
                        <div className="col">

                            <p className="explanation_par"><FormattedMessage
                                id="learnmore.correct.1"
                                defaultMessage='The distributed registry makes it possible to verify the integrity of a document by comparing its footprint with the one which was inserted in the first place. If the footprint generated differs from when it was created then this proves the data has been changed.'/></p>
                        <img src={correct1} className="illustration_midsize" alt="contract" />

                        </div>
                        <div className="col">
                            <p className="explanation_par"><FormattedMessage
                                id="learnmore.correct.2"
                                defaultMessage='We take the footprint and verify when it was put in the distributed ledger.'/></p>
                            <img src={correct2} className="illustration_midsize" alt="contract" />

                        </div>
                    </div>
                    <div className="container">
                        <p className="explanation_par"><FormattedMessage
                            id="learnmore.wrong"
                            defaultMessage='If the digital footprint does not match we will get an error message. That means our document has been modified or is not the good one.'/></p>
                        <div className="row">
                            <div className="col">
                                <img src={wrong1} className="illustration_midsize" alt="contract" />

                            </div>
                            <div className="col">

                                <img src={wrong2} className="illustration_midsize" alt="contract" />

                            </div>
                        </div>

                </div>
                    <p className="explanation_par"><FormattedMessage
                        id="learnmore.hash"
                        defaultMessage='You also cannot regenerate the contract with the just the foot print, this only goes one way. The footprint itself, enables you to verify the contract.
This means that all the documents placed in our database are certified and that we then are able to promise you that your documents will not be modified.'/></p>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h4><FormattedMessage
                                id="learnmore.poi.usage.title"
                                defaultMessage='Proof of immubility has many different type of usage :'/></h4>
                            <ul>
                                <li>
                                    <FormattedMessage
                                        id="learnmore.poi.usage.1"
                                        defaultMessage='Show data ownership without revealing actual data.'/>

                                </li>
                                <li>
                                    <FormattedMessage
                                        id="learnmore.poi.usage.2"
                                        defaultMessage='Verifies the data integrity'/>

                                </li>
                                <li>
                                    <FormattedMessage
                                        id="learnmore.poi.usage.3"
                                        defaultMessage='Timestamp data'/>

                                </li>
                                <li>
                                    <FormattedMessage
                                        id="learnmore.poi.usage.4"
                                        defaultMessage='Proves the ownership of that data'/>

                                </li>
                                <li>
                                    <FormattedMessage
                                        id="learnmore.poi.usage.5"
                                        defaultMessage='No third party is trusted'/>

                                </li>
                            </ul>
                        </div>
                        <div className="col">
                            <h4><FormattedMessage
                                id="learnmore.poi.domain.title"
                                defaultMessage='This will help many domains :'/></h4>
                            <ul>
                                <li>
                                    <FormattedMessage
                                        id="learnmore.poi.domain.1"
                                        defaultMessage='Notary and lawyer services'/>

                                </li>
                                <li>
                                    <FormattedMessage
                                        id="learnmore.poi.domain.2"
                                        defaultMessage='Administrative work'/>

                                </li>
                                <li>
                                    <FormattedMessage
                                        id="learnmore.poi.domain.3"
                                        defaultMessage='Intellectual property instances'/>

                                </li>
                                <li>
                                    <FormattedMessage
                                        id="learnmore.poi.domain.4"
                                        defaultMessage='Supply Chain management'/>

                                </li>
                                <li>
                                    <FormattedMessage
                                        id="learnmore.poi.domain.5"
                                        defaultMessage='Luxury products certification'/>

                                </li>
                                <li>
                                    <FormattedMessage
                                        id="learnmore.poi.domain.6"
                                        defaultMessage='Identity proof and managing'/>

                                </li>
                                <li>
                                    <FormattedMessage
                                        id="learnmore.poi.domain.7"
                                        defaultMessage='College degrees authentication'/>

                                </li>
                                <li>
                                    <FormattedMessage
                                        id="learnmore.poi.domain.8"
                                        defaultMessage='Much more to be created.'/>

                                </li>
                            </ul>

                        </div>
                    </div>

                </div>
                <br/>

                <h4 className="endGoal"><FormattedMessage
                    id="learnmore.poi.endgoal"
                    defaultMessage='The end goal is to make data verifiable without trusted third party.'/></h4>
                <h4 className="endGoal"><FormattedMessage
                    id="learnmore.poi.slogan"
                    defaultMessage='“Proof of Immutability brings trust to data.”'/></h4>

                <br/>


                <blockquote className="blockquote">
                    <p className="mb-0">“We’re trying to unleash the creativity of an entire generation. We’re building a system, on top of which a thousand applications that require trust can be built”</p>
                    <footer className="blockquote-footer">Andrea M. Antonopoulos<cite title="Source Title"></cite></footer>
                </blockquote>

                <div>
                    <hr className="my-3"/>
                    <h3><FormattedMessage
                        id="learnmore.voc.title"
                        defaultMessage='A bit of vocabulary'/></h3>
                    <br/>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h3><FormattedMessage
                                    id="learnmore.voc.ledger.title"
                                    defaultMessage='Ledger'/></h3>
                                <p className="img_desc"><FormattedMessage
                                    id="learnmore.voc.ledger.desc"
                                    defaultMessage='Book or other collection of financial accounts.'/></p>
                                <img src={voc1} className="illustration_midsize" alt="contract" />

                            </div>
                            <div className="col">
                                <h3><FormattedMessage
                                    id="learnmore.voc.dlt.title"
                                    defaultMessage='Distributed Ledger'/></h3>
                                <p className="img_desc"><FormattedMessage
                                    id="learnmore.voc.dlt.desc"
                                    defaultMessage='A distributed ledger (also called a shared ledger or DLT) is a consensus of replicated, shared, and synchronized digital data geographically.
Spread across multiple sites, countries, or institutions. Theret is no central administrator or centralized data storage.'/></p>
                                <img src={voc2} className="illustration_midsize" alt="contract" />

                            </div>
                        </div>

                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h3><FormattedMessage
                                    id="learnmore.voc.stamp.title"
                                    defaultMessage='Stamp'/></h3>
                                <p className="img_desc"><FormattedMessage
                                    id="learnmore.voc.stamp.desc"
                                    defaultMessage=' An instrument for stamping a pattern or mark, in particular an engraved or inked block or die.'/></p>
                                <img src={voc3} className="illustration_midsize" alt="contract" />

                            </div>
                            <div className="col">
                                <h3><FormattedMessage
                                    id="learnmore.voc.timestamp.title"
                                    defaultMessage='Timestamp'/></h3>
                                <p className="img_desc"><FormattedMessage
                                    id="learnmore.voc.timestamp.desc"
                                    defaultMessage='A record of the time of occurrence of a particular event.'/></p>
                                <img src={voc4} className="illustration_midsize" alt="contract" />

                            </div>
                        </div>
                    </div>
                    <hr className="my-3"/>
                    <h3><FormattedMessage
                        id="learnmore.blockchain.title"
                        defaultMessage='Blockchain Simply Explained'/></h3>
                    <br/>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h3><FormattedMessage
                                    id="learnmore.explain.centralized"
                                    defaultMessage='Centralized'/></h3>
                                <img src={blockchain1} className="illustration_midsize" alt="contract" />

                            </div>
                            <div className="col">
                                <h3><FormattedMessage
                                    id="learnmore.explain.decentralized"
                                    defaultMessage='Blockchain'/></h3>
                                <img src={blockchain2} className="illustration_midsize" alt="contract" />

                            </div>
                        </div>
                    </div>
                </div>



            </section>
        </div>
)}
export default explanationPage;