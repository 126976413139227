import React, {Component} from 'react';
import './ExtraLinks.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FormattedMessage} from 'react-intl';



function ExtraLinks() {

    function switchLanguage(language){
        switch (language) {
            case "en":

            case  "fr":
        }
    }


    return(
            <ul className="ExtraLinks">
                <li>
                    <a className="ExtraLinks-links" href="/learn-more"><FormattedMessage
                               id="website.link.learnmore"
                               defaultMessage='Learn more'
                               description="learn more link"/></a>
                </li>
                {/*<li>*/}
                {/*    <a*/}
                {/*        className="ExtraLinks-links"*/}
                {/*        href="#"*/}
                {/*        target="_blank"><FormattedMessage*/}
                {/*        id="website.link.code"*/}
                {/*        defaultMessage='Check the code'*/}
                {/*        description="Code link"/></a>*/}

                {/*</li>*/}
                {/*<li>*/}
                {/*    <a href="#" className="ExtraLinks-links" onClick={ () => { switchLanguage("fr")}  }>fr&nbsp;</a> <a href="#" className="ExtraLinks-links" onClick={ () => { switchLanguage("en")}}>en</a>*/}
                {/*</li>*/}

            </ul>
        )

}
export default ExtraLinks; // Don’t forget to use export default!