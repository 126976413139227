import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import LogoFooter from "./components/LogoFooter";
import LogoHeader from "./components/LogoHeader";
import {IntlProvider} from "react-intl";
import messages_fr from "./translations/fr.json";
import messages_en from "./translations/en.json";

const data = {
    'fr': messages_fr,
    'en': messages_en
};
const language = navigator.language.split(/[-_]/)[0];

ReactDOM.render(
    <IntlProvider locale={language} messages={data[language]}>
        <React.StrictMode>
            <LogoHeader/>
            <LogoFooter/>
            <App/>
        </React.StrictMode>
    </IntlProvider>,

    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
