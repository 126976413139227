import React from 'react';
import './LogoHeader.css';
import sparta_logo from "../assets/spartagroup/Logo-SpartaGroup-2019.png";

function LogoHeader() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const displayed = params.get('sparta');
    console.log(displayed);

    if (displayed=="true"){
        return (
            <div className="LogoHeader">
                <img src={sparta_logo} className="Sparta-logo" alt="sparta_logo" />
            </div>
        );
    }else{
        return(<div className="LogoHeader"></div>);
    }
}
export default LogoHeader;
