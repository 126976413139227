import React, {useEffect, useMemo, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import * as DDZStyle from './DragDropZoneStyle'
// import { sha256 } from 'react-native-sha256';
import CurrentProcessMessage from "./CurrentProcessMessage";
import * as CryptoJS from 'crypto-js';
import "./stamping.css"
import {url} from '../settings';
import {useLocation} from 'react-router-dom'
import cube_img from "../assets/blockchain_cube_empty.svg";
import ExtraLinks from "./ExtraLinks";
import {FormattedMessage} from 'react-intl';


function DropZone(props) {
    const location = useLocation();

    const [step, setStep] = useState(0)
    const [stamp, setStamp] =useState({stamped:false, hash:null})

    // const [file, setFile] = useState(null)
    var file = null

    function changeStep(step){
        setStep(step)
    }

    useEffect(() => {
        if (props.match.params.id){
                checkStamp(props.match.params.id)

        }
    }, [location]);



    function checkStamp(hash) {
        fetch(url + '/ishashtamped/' + hash)
            .then(res => {
                console.log(res)
                return res.json()
            })
            .then((data) => {
                if (!data) return <p>Error</p>
                // this.setState({ contacts: data })
                if (data.stamped == false) {
                    if (!data.message) {
                        setStamp({stamped: false, hash: hash})
                        setStep(2) // Not included in Bitcoin blockchain
                        console.log("the file is not included in the blockchain")
                    } else {
                        setStamp({stamped: false, hash: hash})
                        setStep(3) // Pending calendar transaction
                        console.log("the file is queued to be included in the blockchain")
                    }
                } else {
                    setStamp({stamped: true, hash: data.hash, height: data.height, timestamp: data.timestamp})
                    setStep(4) // File stamped
                    console.log("the file is in the blockchain")


                }
            })
    }

    const {acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject} = useDropzone({
        minSize:0,
        maxSize:1073741824,
        onDropAccepted: files =>{

            let reader = new window.FileReader()
            reader.readAsArrayBuffer(files[0])
            reader.onloadend = () => convertToBuffer(reader).then(()=>{

                setStep(1); // A file has been dropped
                var hash = computeSHA256(file)
                checkStamp(hash);

                return (<li key={file.path}>
                    {file.path} - {(file.size/1024).toFixed(2)} Kbytes
                </li>);
            })

    }

    });


    const style = useMemo(() => ({
        ...DDZStyle.baseStyle,
        ...(isDragActive ? DDZStyle.activeStyle : {}),
        ...(isDragAccept ? DDZStyle.acceptStyle : {}),
        ...(isDragReject ? DDZStyle.rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (
        <section className="container">
            <img src={cube_img} className="App-logo-dyn" alt="logo"/>
            <h1 className="stampTitle">Blockchain Verify</h1>
            <p className="stampDesc">
                <FormattedMessage
                    id="website.description"
                    defaultMessage='Stamped forever.'
                    description="Description"/>

            </p>
            <ExtraLinks/>
            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                <p><FormattedMessage
                    id="drag.description"
                    defaultMessage="Drag 'n' drop some files here, or click to select files"
                    description="drag.info"/></p>
            </div>
            <aside>
                <ul>{file}</ul>
                <CurrentProcessMessage step={step} file={file} stamp={stamp} changeStep={changeStep}/>
            </aside>
        </section>
    );

    async function convertToBuffer(reader) {
        //file is converted to a buffer for upload to IPFS
        const buffer = await Buffer.from(reader.result);
        //set this buffer -using es6 syntax
        file= buffer
    };
}


export default DropZone;


function computeSHA256(file){
    var file_wordArr = CryptoJS.lib.WordArray.create(file); //convert blob to WordArray , see https://code.google.com/p/crypto-js/issues/detail?id=67
    var sha256_hash = CryptoJS.SHA256(file_wordArr); //calculate SHA1 hash
    return sha256_hash.toString()
}


