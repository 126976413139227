import React from 'react';
import './LogoFooter.css';
import bksc_logo from "../assets/bksc/bksc_logo.svg";

function LogoFooter() {

    return (
        <div className="LogoFooter">
                <img src={bksc_logo} className="BKSC-logo" alt="bksc_logo" />
        </div>
    );
}

export default LogoFooter;
