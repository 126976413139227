import React from 'react';
import './CurrentProcessMessage.css';
import validResponse from "../assets/alerts/tick.svg";
import waitingResponse from "../assets/alerts/shape.svg";
import errorResponse from "../assets/alerts/signs.svg";
import CircleLoader from "../circleLoader";
import Button from 'react-bootstrap/Button';
import {url} from '../settings';
import './stamping.css'
import {FormattedMessage} from 'react-intl';


const spinTransition = {
    loop: Infinity,
    ease: "linear",
    duration: 1,
}


function CurrentProcessMessage(props) {

    function renderSwitch(currentStep) {
        switch (currentStep) {
            case 0:
                return (<p className="infoUpload">
                        Your files will <b>not</b> be uploaded. <a href="/learn-more">Learn more.</a></p>)
            case 1:
                return (
                    <div className="MessageResponse">
                        <p>
                        <FormattedMessage
                            id="upload.checking"
                            defaultMessage='Please wait, we are checking the state of this file>'
                            description="Disclaimer"/>
                        </p><CircleLoader/>
                    </div>

                )
            case 2:
                return (
                    <div className="MessageResponse">
                        <div className="card text-center text-white bg-info">
                            <form className="card-body">
                                <form>
                                    <div className="form-group row">
                                        <div className="col-sm-1">
                                            <img src={errorResponse} className="MessageResponse-img" alt=""/>
                                        </div>
                                        <p className="MessageResponse-title col-sm-11">
                                            <FormattedMessage
                                            id="upload.invalid"
                                            defaultMessage="There is not record of this document on the bitcoin blockchain"
                                            description="Upload status"/> </p>
                                    </div>
                                </form>
                                <div>{<Button type="button" class="btn btn-primary" onClick={() => {
                                    submitHash(props.stamp.hash)
                                }}><FormattedMessage
                                    id="upload.stampButton"
                                    defaultMessage="Stamp Now !"
                                    description="Stamp button"/></Button>}</div>
                            </form>

                        </div>
                        <p className="infoUpload">
                            Your files will <b>not</b> be uploaded. <a href="/learn-more">Learn more.</a></p>
                    </div>
                )
            case 3:
                return (
                    <div className="MessageResponse">
                        <div className="card text-center text-white  bg-warning">
                            <form className="card-body">
                                <form>
                                    <div className="form-group row">
                                        <div className="col-sm-1">
                                            <img src={waitingResponse} className="MessageResponse-img" alt=""/>
                                        </div>
                                        <p className="MessageResponse-title col-sm-11"><FormattedMessage
                                            id="upload.inprogress"
                                            defaultMessage="There is a pending transaction to include the stamp of this file in the bitcoin blockchain"
                                            description="Upload status"/> </p>
                                    </div>
                                </form>
                            </form>
                        </div>
                    </div>
                )
            case 4:
                var url = "https://www.blockchain.com/btc/block/" + props.stamp.height
                return (
                    <div className="MessageResponse">
                        <div className="card text-center text-white  bg-success">
                            <form className="card-body">
                                <form>
                                    <div className="form-group row">
                                        <div className="col-sm-1">
                                            <img src={validResponse} className="MessageResponse-img" alt=""/>
                                        </div>
                                        <p className="MessageResponse-title col-sm-11">
                                            <FormattedMessage
                                                id="upload.stamped"
                                                defaultMessage='This document is currently stamped in the bitcoin blockchain at the block'
                                                description="Disclaimer"/><a href={url} target="_blank"> #{props.stamp.height}</a>
                                        </p>
                                    </div>
                                </form>

                            </form>
                        </div>
                    </div>

                )
            case 5:
                return (
                    <div className="MessageResponse">
                        <div className="card text-center text-white bg-info">
                            <div className="card-body">
                                <p className="MessageResponse-title">
                                    <FormattedMessage
                                        id="upload.submitted"
                                        defaultMessage='The digital footprint of your file (*) has been submitted to the Bitcoin blockchain, please wait for the transaction to be confirmed'
                                        description="Upload status"/>
                                        </p>
                                <div>{<Button type="button" class="btn btn-primary" onClick={() => {
                                    props.changeStep(0)
                                }}>OK</Button>}</div>

                            </div>
                        </div>
                       <div className="hash_info"><p>*{props.stamp.hash}</p></div>
                    </div>
                )
            case 6:
                return (
                    <div className="MessageResponse">
                    <CircleLoader/>
                </div>)
            default:
                return (<p></p>);
        }

    }

    return (
        <div>
            {renderSwitch(props.step)}
        </div>
    )

    function submitHash(hash) {
        props.changeStep(6)
        fetch(url+'/submitHash/' + hash, {method: 'POST'})
            .then(response => response.json())
            .then((data) => {
                console.log(data)
                props.changeStep(5)
            });
    }
}

export default CurrentProcessMessage;


