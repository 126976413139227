import React from 'react';
import './App.css';
import DropZoneStamping from './components/DragDropZone'
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/stamping.css'
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import explanationPage from "./components/explanation_page";


function App() {

  return (

    <div className="App">
      <Router>

        <header className="App-header">
          <Switch>
            <Route exact path="/" component={DropZoneStamping}/>
            <Route path="/hash/:id" component={DropZoneStamping}/>
            <Route path="/learn-more" component={explanationPage}/>
          </Switch>
          {/*<DropZoneStamping/>*/}
        </header>
      </Router>
    </div>
  );
}

export default App;
